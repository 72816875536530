<template>
  <div>
    <v-stepper
      v-model="e1"
      style="
        z-index: 100;
        position: absolute;
        top: 10px;
        left: 10px;
        width: 100vw;
        min-height: 100%;
    
      "
      alt-labels
    >
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1">Buyer Type</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2"
          >Personal Details</v-stepper-step
        >

        <v-divider></v-divider>

        <v-stepper-step step="3"> Name of step 3 </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step step="4"> Name of step 3 </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step step="5"> Name of step 3 </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step step="6"> Name of step 3 </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="mb-12" color="grey lighten-3" height="300px">
            <v-card-title>Client Info</v-card-title>
            <v-row class="text-center">
              <v-col class="mb-4" offset="1" cols="5" sm="5" md="5">
                <span>Type</span>
                <v-radio-group v-model="defaultInfo.buyerType">
                  <v-radio
                    v-for="item in radioGroupOptions"
                    :key="item"
                    :label="
                      item === 'person' ? 'Individual' : 'Company / Trust'
                    "
                    :value="item"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col class="mb-4" offset="0" cols="5" sm="5" md="5">
                <span>No of Buyers</span>
                <v-radio-group v-model="defaultInfo.buyersDirectorsLength">
                  <v-radio
                    v-for="item in numberOfBuyers"
                    :key="item"
                    :id="item.toString()"
                    :label="item === 1 ? 'One' : 'Two'"
                    :value="item"
                    @click="changedNumberOfBuyers($event)"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card>
          <v-btn color="primary" @click="e1 = 2"> Continue </v-btn>
          <v-btn text @click="cancelEdit"> Cancel </v-btn>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card class="mb-12" color="grey lighten-3" height="680px">
            <v-card-title>Personal Details</v-card-title>
            <v-row class="text-center">
              <v-col class="mb-4" offset="1" cols="10" sm="10" md="10">
                <v-radio-group
                  v-model="defaultInfo.buyersDirectors[0].maritalStatus"
                  row
                >
                  <v-radio
                    v-for="item in maritalStatus"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row class="text-center">
              <v-col class="mb-4" offset="1" cols="5" sm="5" md="5">
                <v-text-field
                  label="First Name"
                  v-model="defaultInfo.buyersDirectors[0].firstName"
                ></v-text-field>
              </v-col>
              <v-col class="mb-4" offset="0" cols="5" sm="5" md="5">
                <v-text-field
                  label="Last Name"
                  v-model="defaultInfo.buyersDirectors[0].lastName"
                ></v-text-field>
              </v-col>
              <v-col class="mb-4" offset="1" cols="5" sm="5" md="5">
                <v-text-field
                  label="ID Number / Passport"
                  v-model="defaultInfo.buyersDirectors[0].id"
                ></v-text-field>
              </v-col>
              <v-col class="mb-4" offset="0" cols="5" sm="5" md="5">
                <v-text-field label="email"></v-text-field>
              </v-col>
              <v-col class="mb-4" offset="1" cols="5" sm="5" md="5">
                <v-text-field label="mobile"></v-text-field>
              </v-col>
              <v-col class="mb-4" offset="0" cols="5" sm="5" md="5">
                <v-text-field label="phone"></v-text-field>
              </v-col>
              <v-col class="mb-4" offset="1" cols="5" sm="5" md="5">
                <v-textarea
                  label="postal address"
                  outlined
                  auto-grow
                  rows="5"
                ></v-textarea>
              </v-col>
              <v-col class="mb-4" offset="0" cols="5" sm="5" md="5">
                <v-textarea
                  label="residential address"
                  outlined
                  auto-grow
                  rows="5"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card>

          <v-btn color="primary" @click="e1 = 3"> Continue </v-btn>

          <v-btn text @click="cancelEdit"> Cancel </v-btn>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card class="mb-12" color="grey lighten-3" height="300px"></v-card>

          <v-btn color="primary" @click="e1 = 1"> Continue </v-btn>

          <v-btn text @click="cancelEdit"> Cancel </v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
export default {
  props: {
    defaultInfo: Object
  },
  data() {
    return {
      e1: 1,
      radioGroup: 1,
      radioGroupOptions: ["person", "legal"],
      numberOfBuyers: [1, 2],
      maritalStatus: ["Not married", "Married - ANC", "Married - COP"]
    };
  },
  mounted() {
    console.log("defaultInfo", this.defaultInfo);
  },
  methods: {
    changedNumberOfBuyers() {
      console.log(this.defaultInfo.buyersDirectorsLength);
      console.log(this.defaultInfo.buyersDirectors.length);
      //   if (this.defaultInfo.buyersDirectorsLength === 2) {
      //       if (this.defaultInfo.buyersDirectors.length === 1) {
      //           let insert = {

      //           }
      //       }
      //   }
    },
    cancelEdit(e) {
      console.log(e);

      this.$emit("closeForm", false);
    }
  }
};
</script>
