<template>
  <div class="about">
    <v-row class="text-center">
      <v-card class="mx-auto my-12" max-width="100vw" width="100vw">
        <v-card-title v-if="$route.param !== 'none'">Edit Sale</v-card-title>
        <v-card-title v-else>Edit Sale</v-card-title>
        <v-card-text>
          <v-row align="center" class="mx-0">
            <v-col class="mb-4" offset="1" cols="6" sm="5" md="5">
              <v-img
                height="550"
                src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
              ></v-img>
            </v-col>
            <v-col class="mb-4" offset="0" cols="5" sm="5" md="5">
              <v-col class="mb-4" offset="1" cols="10" sm="10" md="10">
                <v-text-field
                  label="Development"
                  filled
                  readonly
                  outlined
                  v-model="defaultInfo.development"
                ></v-text-field>
              </v-col>
              <v-col class="mb-4" offset="1" cols="10" sm="10" md="10">
                <v-text-field
                  label="Block"
                  filled
                  readonly
                  outlined
                  v-model="defaultInfo.block"
                ></v-text-field>
              </v-col>
              <v-col class="mb-4" offset="1" cols="10" sm="10" md="10">
                <v-text-field
                  label="Unit"
                  filled
                  readonly
                  outlined
                  v-model="defaultInfo.unit"
                ></v-text-field>
              </v-col>
              <v-col class="mb-4" offset="1" cols="10" sm="10" md="10">
                <v-btn color="grey" @click="editSale">Edit Sale</v-btn>
              </v-col>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row class="text-center" v-if="editSaleComponent">
      <MongoSales
        :defaultInfo="defaultInfo"
        :key="componentKey"
        @closeForm="closeForm"
      />
    </v-row>

    <v-snackbar v-model="snackbar" bottom top shaped color="blue">
      {{ snackbarMessage }}
      <v-btn color="pink" text timeout="10000" @click="snackbar = false"
        >Close</v-btn
      >
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
let url = process.env.VUE_APP_BASEURL;
import MongoSales from "../components/MongoSales";
export default {
  name: "NewSale",
  components: {
    MongoSales
  },
  metaInfo: {
    title: "About us",
    titleTemplate: "CPC - %s",
    meta: [
      {
        name: `description`,
        content: `About CPC here.`
      }
    ],
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },
  data() {
    return {
      componentKey: 0,
      editSaleComponent: false,
      selection: 1,
      documentType: "",
      snackbar: false,
      snackbarMessage: "",

      defaultInfo: {
        unit: "", //
        block: "", //
        development: "", //
        size: 0,
        bedrooms: 0,
        bathrooms: 0,
        unitType: [
          {
            category: "",
            description: ""
          }
        ],
        basePrice: 0,
        additionalBath: false,
        study: false,
        additionalParking: 0,
        parking: [
          {
            originalBayNumber: "",
            additionalBay: false,
            additionalBayNumber: ""
          }
        ],
        moodBoard: "Serene",
        kitchenOptions: "",
        extras: [
          {
            description: "",
            cost: 0
          },
          {
            description: "",
            cost: 0
          }
        ],
        deductions: [
          {
            description: "",
            cost: 0
          },
          {
            description: "",
            cost: 0
          }
        ],
        flooring: [
          {
            type: "",
            area: "",
            cost: 0
          },
          {
            type: "",
            area: "",
            cost: 0
          }
        ],
        stoveOptions: [
          {
            type: "",
            cost: 0
          },
          {
            type: "",
            cost: 0
          }
        ],

        contractPrice: 0,
        saleDate: "",
        bondApprovalDate: "",
        lodgementDate: "",
        transferDate: "",
        sold: false,
        salesAgent: "",
        salesAgentMobile: "",
        buyerType: "", //
        cashBonded: "",
        deposit: 0,
        depositDate: null,
        buyersDirectorsLength: 1,
        buyersDirectors: [
          //Number Length
          {
            firstName: "",
            lastName: "",
            id: "",
            maritalStatus: "",
            postalAddress: "",
            residentialAddress: "",

            telephone: "",
            mobile: "",
            email: "",
            fileUploads: [
              {
                fileOTP: "",
                fileID: "",
                fileBank: "",
                filePOP: "",
                fileFICA: "",
                filePayslip: ""
              }
            ]
          }
        ],
        plan: "",
        smartRow: "",
        smartColumn: ""
      }
    };
  },
  mounted() {
    console.log(this.$route.params.id);
    if (this.$route.params.id === "none") {
      this.documentType = "New";
    } else {
      this.documentType = "Edit";
      this.getUnitInfo();
    }
    // console.log(this.$route.params.id)
  },
  methods: {
    editSale() {
      this.editSaleComponent = true;
    },
    closeForm(event) {
      this.editSaleComponent = event;
      console.log("CANCEL", event);
    },

    async getUnitInfo() {
      let data = {
        unit: this.$route.params.id
      };
      await axios({
        method: "post",
        url: `${url}/getSingleUnit`,
        data: data
      })
        .then(
          response => {
            console.log(response.data);
            this.defaultInfo = response.data;
            this.defaultInfo.buyersDirectorsLength = this.defaultInfo.buyersDirectors.length;
          },
          error => {
            console.log(error);
          }
        )
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>

<style scoped></style>
